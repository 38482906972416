.vich-image,
.vich-file {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap;
  justify-content: center;

  .custom-file {
    margin-bottom: 0 !important;
  }

  .form-group {
    margin-left: 25px;
  }
  img {
    height: 250px;
  }
}

.m-0 .vich-file {
  .custom-file {
    margin-bottom: 0;
  }
}
.vich-file a {
  display: flex;
}

.margin-form-right > .form-check {
  margin-right: 2rem;
}

.footer-form {
  backdrop-filter: blur(12px);
  background: #fff;
  border-left: 1px solid #dee2e6 !important;
  border-top: 1px solid #dee2e6 !important;
  bottom: 0;
  left: 0;
  opacity: 0.9;
  padding: 1rem;
  position: fixed;
  right: 0;
  z-index: 10;
}

.two-columns .form-check {
  display: flex;
  width: 50%;

  input:checked ~ label {
    font-weight: 600;
    color: #3b5cad;
  }
}
