body {
  font-size: 0.8rem;
}

.navbar {
  z-index: 9999;
}

.dropdown-menu {
  font-size: 0.8rem;
}

table.dataTable tbody td:hover {
  cursor: default;
}

.card-footer {
  overflow: hidden;
}

.col-form-label-sm {
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.7rem;
  /*border-bottom: 1px solid rgba(0,0,0,.125);*/
}

.form-control-plaintext {
  font-weight: 600;
  font-size: 0.8rem;
  padding-top: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}

.form-control {
  font-weight: 600;
  font-size: 0.8rem;
}

.blockquote {
  margin-bottom: 0;
}

.modal {
  z-index: 99999;
  padding-left: 17px;
  padding-right: 17px;
  margin-top: 17px;
}

.modal-dialog-full {
  min-width: 100%;
  margin: 0;
}
.modal-dialog-full .modal-content {
  min-height: 95vh;
}

#map,
#mapDoublon {
  height: 350px;
}

#mapZoom {
  height: 85vh;
}

.form-control-textarea-scrolly {
  height: 80px;
  overflow-y: auto;
}

.step {
  list-style: none;
  margin: 0.2rem 0;
  width: 100%;
}
.step .step-item {
  -ms-flex: 1 1 0;
  flex: 1 1 0;
  margin-top: 0;
  min-height: 1rem;
  position: relative;
  text-align: center;
}
.step .step-item:not(:first-child)::before {
  background: #0069d9;
  content: '';
  height: 2px;
  left: -50%;
  position: absolute;
  top: 9px;
  width: 100%;
}
.step .step-item a {
  color: #acb3c2;
  display: inline-block;
  padding: 20px 10px 0;
  text-decoration: none;
}
.step .step-item a::before {
  background: #0069d9;
  border: 0.1rem solid #fff;
  border-radius: 50%;
  content: '';
  display: block;
  height: 0.9rem;
  left: 50%;
  position: absolute;
  top: 0.2rem;
  transform: translateX(-50%);
  width: 0.9rem;
  z-index: 1;
}
.step .step-item.active a::before {
  background: #fff;
  border: 0.1rem solid #0069d9;
}
.step .step-item.active ~ .step-item::before {
  background: #e7e9ed;
}
.step .step-item.active ~ .step-item a::before {
  background: #e7e9ed;
}

.form-group {
  margin-bottom: 1em;
}

.card-header.bgBlue {
  background-color: #007bff;
}
.card-body.bgBlue {
  background-color: #e7eeff;
}

.strong {
  font-weight: 700;
}
.line-through {
  text-decoration: line-through;
}

select option.hidden,
.btn.hidden {
  display: none;
}

.flash-alert {
  position: fixed;
  right: 5%;
  bottom: 50px;
  opacity: 1;
  -webkit-animation: fadeTop ease-out 5s; /* Safari 4+ */
  -moz-animation: fadeTop ease-out 5s; /* Fx 5+ */
  -o-animation: fadeTop ease-out 5s; /* Opera 12+ */
  animation: fadeTop ease-out 5s; /* IE 10+, Fx 29+ */
  font-size: 15px;

  button {
    margin-left: 10px;
  }
}

.dataTables_info {
  font-size: 14px;
}
