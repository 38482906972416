@font-face {
  font-family: 'wigrum';
  src: url('./font/wigrum-regular.otf');
}

body {
  font-family: 'wigrum', 'overpass', 'Arial, Verdana, Helvetica, helve, sans-serif';
}

.img-residence-principale {
  background: url(../images/la-maison-de-l-investisseur-residence-principale.jpg);
  background-size: cover;
  background-position: center;
}

.image-header {
  height: 500px;
}

.searchBloc {
  background: linear-gradient(45deg, #00008b 0%, #3b5cad 100%);
  height: auto;
}
.searchBloc label {
  color: white;
}
.paddingTop2Percent {
  padding-top: 2%;
}
.paddingBottom1Percent {
  padding-bottom: 1%;
}

.lien-programme,
.lien-programme:hover {
  color: inherit;
  text-transform: none;
  text-decoration: none;
}
.card-programme {
  border: none;
  line-height: 1.6;
  -webkit-box-shadow: none;
  box-shadow: none;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

.card-programme p {
  line-height: 1;
}

.card-programme:hover {
  z-index: 9;
  border-radius: 10px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
  transform: scale(1.05);
}

.programme-text {
  font-size: 1.1em;
}

.searchBloc .btnSearchInit {
  background: #ff5f00;
  border-color: darkorange;
  color: #fff;
}

@media (min-width: 992px) {
  .col-align-middle {
    display: table-cell;
    height: 100%;
    vertical-align: bottom;
    float: none;
  }
}

.display-table {
  display: table;
  height: 100%;
}

.divider-orange {
  border-top: 2px solid #ff5f00;
}

.text-orange {
  color: #ff5f00;
}

.width150 {
  width: 150px;
}

.no-padding {
  padding: 0;
}

.section-programme {
  margin: 1% 0 1% 0;
}

.visuel-fiche-programme {
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: 50% 50% !important;
}

.row-programme {
  margin: 0 5%;
}

.row-programme-remove {
  margin: 0 calc(-5% - 10px);
}

.noBackgroundColor {
  background-color: white;
}

#container-map {
  width: 100%;
  height: 500px;
  position: relative;
  overflow: hidden;
}

.blockHeader {
  width: 277px;
  padding: 35px 25px 15px;
  z-index: 10;
  margin-top: -75px;
}

.bg-vertical {
  background: #f6287b;
  background: -moz-linear-gradient(45deg, #00008b 0%, #2c75ff 100%);
  background: -webkit-linear-gradient(45deg, #00008b 0%, #2c75ff 100%);
  background: linear-gradient(45deg, #00008b 0%, #2c75ff 100%);
}

.test {
  color: white;
  font-size: 1.6em;
  line-height: 1.3;
}

.modal-body .form-group {
  margin-bottom: 5px;
}

.no-result {
  background-color: #f8f8f8;
  padding: 20px;
  margin-bottom: 15px;
}

.no-result .icon-result {
  font-size: 60px;
  color: red;
}

.section-programme p {
  font-size: 1.1em;
}

/* Sidebar */

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, 0.1);
}

.sidebar-sticky {
  position: relative;
  top: 0;
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto;
}

@supports ((position: -webkit-sticky) or (position: sticky)) {
  .sidebar-sticky {
    position: -webkit-sticky;
    position: sticky;
  }
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link.active {
  color: #007bff;
}

[role='main'] {
  padding-top: 20px;
}
.nav-link:hover {
  color: grey;
}

.div-img-programme {
  overflow: hidden;
}

.badge-custom {
  display: inline-block;
  padding: 0.25em 0.4em;
  /* font-size: 75%; */
  /* font-weight: 700; */
  line-height: 1.5;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  width: 100%;
  color: black !important;
}

.badge-white {
  color: black !important;
}

.badge-lightYellow {
  background-color: #ffff00;
}

.badge-darkYellow {
  background-color: #ffc000;
}

.badge-pink {
  background-color: #ff33da;
}
.badge-lightPink {
  background-color: #ffc0cb;
}

.badge-darkPink {
  background-color: #c71585;
}

.badge-fushia {
  background-color: #ff33cc;
}

.badge-lightGreen {
  background-color: #9fe855;
}

.badge-red {
  background-color: rgba(255, 0, 0, 0.5);
}

.badge-orange {
  background-color: #ffa500;
}

.badge-lightGrey {
  background-color: #d3d3d3;
}

.badge-darkGrey {
  background-color: #a9a9a9;
}

.visuel-lot {
  height: 250px;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: 50% 50% !important;
}

.nav-pills > a {
  color: #212529;
}

.nav-link:hover {
  color: black !important;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #212529;
  background-color: #f8f9fa !important;
}

.card-header {
  background-color: white;
}

.card {
  margin-bottom: 2rem;
}

.small-text {
  font-size: 90%;
}

label.required:not(.radio-custom):after {
  content: '*';
  color: red;
}

.col-form-label {
  padding-top: 0;
}

#header {
  height: 100px;
  display: block;
  position: relative;
}
#header::after {
  content: '';
  background-image: url('../images/motif.jpg');
  opacity: 0.1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}

.bg-motif:before {
  content: '';
  background-image: url('../../public/images/motif.jpg');
  opacity: 0.1;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  position: absolute;
  z-index: -1;
}

.text-fushia {
  color: #ff33cc;
}
