@import '~bootstrap/scss/functions';
$custom-file-text: (
  fr: 'Parcourir',
);

@import '~bootstrap/scss/bootstrap';

@import '~bootstrap-select/sass/bootstrap-select';
//ICONE
@import '~@fortawesome/fontawesome-free/css/all';

//DataTable
@import '~datatables.net-bs4';
@import '~flatpickr/dist/flatpickr.min.css';

//SELECT
@import '~bootstrap-select/dist/css/bootstrap-select.min.css';

@import '~jquery-ui/themes/base/core.css';
@import '~jquery-ui/themes/base/theme.css';

@import 'base';
@import 'style';
@import 'form';
@import 'carousel';
@import 'spinner';
@import '_searchBar';
@import 'placeholder';

@import '~quill/dist/quill.snow.css';

html {
  scroll-behavior: smooth;
}

[draggable] {
  user-select: none;
  -webkit-user-drag: element;
  cursor: move;
}
table.sortable {
  i.fa.fa-arrows {
    color: #999;
  }
  tr.over {
    border: 1px dashed blue;
  }
}

.card-max-height {
  max-height: 500px;
  overflow-y: auto;
}

.title-realodge {
  color: #3b58b8 !important;
}

.bg-realodge {
  background-color: #ff6a00 !important;
}

.bg-blue {
  background-color: #3b58b8;
}

.orange-realodge {
  color: #ff6a00 !important;
}

.bootstrap-select .btn {
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  font-size: 0.8rem;
}

.btn-realodge {
  background-color: #ff5f00 !important;
  border-color: #ff5f00 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.carousel-indicators li {
  background-color: #ff6a00 !important;
}

.carousel-caption h5 {
  color: #ff6a00 !important;
  font-weight: bold !important;
}

.carousel-control-prev,
.carousel-control-next {
  opacity: 1 !important;
}
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23FF6A00' viewBox='0 0 8 8'%3E%3Cpath d='M5.25 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E") !important;
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23FF6A00' viewBox='0 0 8 8'%3E%3Cpath d='M2.75 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E") !important;
}

#header img {
  height: 45px;
}
@include media-breakpoint-up(xl) {
  #header img {
    height: 65px;
  }
}

.radio-toolbar {
  padding: 0;
  display: flex;
}
.radio-toolbar .form-check {
  padding: 0;
  padding-right: 1rem;
}

.radio-toolbar input {
  opacity: 0;
  position: fixed;
  width: 0;
}

.radio-toolbar label {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.5rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  color: #3b58b8;
  border-color: #3b58b8;
  cursor: pointer;
  width: 200px;
  margin-bottom: 10px;
}

.radio-toolbar label:hover {
  text-decoration: none;
  color: #fff;
  background-color: #4c73f1 !important;
  border-color: #4c73f1 !important;
}

.radio-toolbar input:checked + label {
  color: #fff;
  background-color: #3b58b8;
  border-color: #3b58b8;
}

element.style {
  max-height: 538.25px;
  overflow: hidden;
  min-height: 142px;
  position: absolute;
  will-change: transform;
  top: 0px;
  left: 0px;
  z-index: 9999;
  transform: translate3d(0px, 33px, 0px);
}

.dropdown-menu.show {
  z-index: 99999 !important;
}
