@media (max-width: 767px) {
  #carouselTree .carousel-inner .carousel-item > div {
    display: none;
  }
  #carouselTree .carousel-inner .carousel-item > div:first-child {
    display: block;
  }

  #carouselTree .carousel-inner .carousel-item-right.active,
  #carouselTree .carousel-inner .carousel-item-next {
    transform: translateX(100%);
  }

  #carouselTree .carousel-inner .carousel-item-left.active,
  #carouselTree .carousel-inner .carousel-item-prev {
    transform: translateX(-100%);
  }
}

#carouselTree .carousel-inner .carousel-item.active,
#carouselTree .carousel-inner .carousel-item-next,
#carouselTree .carousel-inner .carousel-item-prev {
  display: flex;
}

/* medium - display 4  */
@media (min-width: 767px) {
  #carouselTree .carousel-inner .carousel-item-right.active,
  #carouselTree .carousel-inner .carousel-item-next {
    transform: translateX(33%);
  }

  #carouselTree .carousel-inner .carousel-item-left.active,
  #carouselTree .carousel-inner .carousel-item-prev {
    transform: translateX(-33%);
  }
}

#carouselTree .carousel-inner .carousel-item-right,
#carouselTree .carousel-inner .carousel-item-left {
  transform: translateX(0);
}
