//
// Rotating border
//

@keyframes spinner-border {
  to {
    transform: rotate(360deg);
  }
}

.spinner-border {
  position: relative;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  overflow: hidden;
  text-indent: -999em;
  vertical-align: text-bottom;
  border: 0.25em solid;
  border-color: currentColor transparent currentColor currentColor;
  border-radius: 50%;
  animation-name: spinner-border;
  animation-duration: 0.75s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.spinner-border-reverse {
  border-color: transparent currentColor transparent transparent;

  &::after {
    position: absolute;
    top: -0.25em;
    right: -0.25em;
    bottom: -0.25em;
    left: -0.25em;
    display: inline-block;
    content: '';
    border: 0.25em solid rgba(0, 0, 0, 0.1);
    border-radius: 50%;
  }
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;

  &.spinner-border-reverse::after {
    border-width: 0.2em;
  }
}

//
// Growing circle
//

@keyframes spinner-grow {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: scale(1);
  }
}

.spinner-grow {
  position: relative;
  display: inline-block;
  width: 2rem;
  height: 2rem;
  overflow: hidden;
  text-indent: -999em;
  vertical-align: text-bottom;
  background-color: currentColor;
  border-radius: 50%;
  animation-name: spinner-grow;
  animation-duration: 0.75s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}
